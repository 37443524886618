.LandingDapps {
  background-image: url("../../images/landingdapp.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 30rem;
}
.LandingDapps .Header {
  height: 30vh;
  padding-bottom: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.LandingDapps .Header h1 {
  color: #c0c0c0;
  text-shadow: 1px 2px 4px black;
  font-size: 4rem;
  text-align: center;
  width: 100%;
}

@media screen and (max-width: 576px) {
  .LandingDapps .Header {
    height: 60vh;
  }
  .LandingDapps .Header h1 {
    font-size: 2rem;
  }
  .LandingDapps .Header p {
    font-size: 1.3rem;
    width: 90%;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .LandingDapps .Header {
    height: 70vh;
  }
  .LandingDapps .Header h1 {
    font-size: 3rem;
  }
  .LandingDapps .Header p {
    font-size: 1.4rem;
    width: 80%;
  }
}
.LandingDappsbutton {
  background-color: rgb(84, 214, 240);
  width: fit-content;

  align-items: center;
  align-self: center;
  border-radius: 25px;
  padding: 5px;
  width: 150px;
  height: 50px;
  font-size: larger;
  font-family: "Times New Roman", Times, serif;
  font-weight: bolder;
  margin-bottom: 20px;

  margin-top: 20px;
}
.LandingDappsbutton:hover {
  background-color: rgb(239, 231, 142);
  color: rgb(240, 22, 185);
  border: #c0c0c0;
}
