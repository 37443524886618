.Login {
    height: 73vh;
}

.Login .Layout {
    width: 25%;
    font-family: "Roboto", sans-serif;
}
.Login .Layout input {
    border: 1px solid black;
}
.Login .Layout input::placeholder {
    font-size: 0.9rem;
    color: black;
}
.Login .Layout i {
    top: 10px;
    right: 10px;
}
.Login a {
    color: black;
}
.Login a:hover {
    color: red !important;
}
.Login .shakeError {
    font-size: 0.8rem;
    animation: shake 0.5s;
}
@keyframes shake {
    0% {
        transform: translateX(0);
    }
    10% {
        transform: translateX(-10px);
    }
    20% {
        transform: translateX(10px);
    }
    30% {
        transform: translateX(-10px);
    }
    40% {
        transform: translateX(10px);
    }
    50% {
        transform: translateX(-10px);
    }
    60% {
        transform: translateX(10px);
    }
    70% {
        transform: translateX(-10px);
    }
    80% {
        transform: translateX(10px);
    }
    90% {
        transform: translateX(-10px);
    }
    100% {
        transform: translateX(0);
    }
}
@media screen and (max-width: 767px) {
    .Login .Layout {
        width: 60%;
    }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
    .Login .Layout {
        width: 40%;
    }
}
