.Transcribe {
    height: 100vh;
    background-color: #000000ee;
    box-sizing: border-box !important;
}
.Transcribe {
    height: 90vh;
    background-color: #282c34;
    box-sizing: border-box !important;
}
.Transcribe #imageEditor {
    color: #9ca5b4;
    background-color: #282c34;
}
.Transcribe .Layout .UpperCard {
    width: 90%;
    height: 50vh;
    border: 1px dashed gray;
}
.Transcribe .Layout .UpperCard .FormCard {
    height: 70%;
    width: 25%;
    color: #9ca5b4;
}
.Transcribe .Layout .UpperCard .FormCard input[type="file"] {
    display: none;
}
.Transcribe .shakeError {
    font-size: 0.8rem;
    animation: shake 0.5s;
}
@keyframes shake {
    0% {
        transform: translateX(0);
    }
    10% {
        transform: translateX(-10px);
    }
    20% {
        transform: translateX(10px);
    }
    30% {
        transform: translateX(-10px);
    }
    40% {
        transform: translateX(10px);
    }
    50% {
        transform: translateX(-10px);
    }
    60% {
        transform: translateX(10px);
    }
    70% {
        transform: translateX(-10px);
    }
    80% {
        transform: translateX(10px);
    }
    90% {
        transform: translateX(-10px);
    }
    100% {
        transform: translateX(0);
    }
}
