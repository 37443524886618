.Translate {
    height: 90vh;
    background-color: #000000ee;
    box-sizing: border-box !important;
}
.Translate .Layout {
    height: 70%;
    width: 80%;
    background-color: #222222;
    box-sizing: border-box;
    box-sizing: border-box !important;
}
.Translate .Layout .leftCard,
.Translate .Layout .rightCard {
    border: 1px solid gray;
    box-sizing: border-box !important;
}
.Translate .Layout select optgroup {
    height: 50px !important;
}
.Translate .Layout textarea {
    width: 100%;
    height: 50vh !important;
    background-color: transparent;
    box-sizing: border-box !important;
    padding: 11px;
    border: none;
    resize: none;
}
/* width */
.Translate .Layout .leftCard textarea::-webkit-scrollbar,
.Translate .Layout select::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.Translate .Layout .leftCard textarea::-webkit-scrollbar-track,
.Translate .Layout select::-webkit-scrollbar-track {
    background: gray !important;
}

/* Handle */
.Translate .Layout .leftCard textarea::-webkit-scrollbar-thumb,
.Translate .Layout select::-webkit-scrollbar-thumb {
    background: #ffffff;
    border-radius: 10px;
}

/* Handle on hover */
.Translate .Layout .leftCard textarea::-webkit-scrollbar-thumb:hover,
.Translate .Layout select::-webkit-scrollbar-thumb:hover {
    background: #ffffff90;
}
