.Annotate {
    height: 90vh;
    background-color: #282c34;
    box-sizing: border-box !important;
}
.Annotate .Layout .UpperCard {
    width: 90%;
    height: 50vh;
    border: 1px dashed gray;
}
.Annotate .Layout .UpperCard .FormCard {
    height: 70%;
    width: 25%;
    color: #9ca5b4;
}
.Annotate .Layout .UpperCard .FormCard input[type="file"] {
    display: none;
}
.Annotate .Layout .UpperCard .FormCard .ImagePreview .ImageDiv {
    height: 15vh;
    width: 100%;
    overflow: hidden;
    position: relative;
}
.Annotate .Layout .UpperCard .FormCard .ImagePreview .ImageDiv img {
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin: auto;
}
.Annotate .ImageEditor {
    height: 100%;
    width: 100%;
}
.Annotate .ImageEditor .filters {
    height: 6%;
    background-color: #ffffff;
}

.Annotate .ImageEditor .ImageSide {
    height: 90%;
    width: 100%;
    overflow: hidden;
    position: relative;
}
.Annotate .ImageEditor .ImageSide .landscape {
    max-height: 100%;
    max-width: 90vw;
    display: block;
    margin: auto;
}
.Annotate .ImageEditor .ImageSide .portrait {
    max-height: 80vh;
    max-width: 100%;
    display: block;
    margin: auto;
}
