.CommunityForum {
  background-image: url("../../images/community.webp");
  background-repeat: no-repeat;
  background-size: cover;
}
.CommunityForum .Header {
  height: 30vh;
  padding-bottom: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.CommunityForum .Header h1 {
  color: #c0c0c0;
  text-shadow: 1px 2px 4px black;
  font-size: 4rem;
  text-align: center;
  width: 100%;
}
.CommunityForum .Content {
  width: 90%;
  margin: auto;
  font-size: 1.1rem;
}
@media screen and (max-width: 576px) {
  .CommunityForum .Header {
    height: 60vh;
  }
  .CommunityForum .Header h1 {
    font-size: 2rem;
  }
  .CommunityForum .Header p {
    font-size: 1.3rem;
    width: 90%;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .CommunityForum .Header {
    height: 70vh;
  }
  .CommunityForum .Header h1 {
    font-size: 3rem;
  }
  .CommunityForum .Header p {
    font-size: 1.4rem;
    width: 80%;
  }
}
