.Vision .Heading {
    height: 50vh;
    background-image: url("../../images/pexels-kampus-production-6248988.jpg");
    background-size: cover;
    background-attachment: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Vision .Heading h1 {
    color: #8490ff;
    text-shadow: 2px 3px 6px rgb(47, 54, 121);
    font-size: 6rem;
}
.Vision main {
    background-color: #fffff0;
    margin: 100px 0;
    width: 100%;
}
.Vision main div {
    height: 100%;
}
.Vision main p {
    height: 100%;
    font-size: 1.1rem;
    display: flex;
    align-items: center;
}

@media screen and (max-width: 576px) {
    .Vision main {
        margin: 20px 0;
    }
    .Vision main p {
        font-size: 0.9rem;
    }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
    .Vision main {
        margin: 60px 0;
    }
    .Vision main p {
        font-size: 1rem;
    }
}
