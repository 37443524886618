.navbar {
    /* height: 10vh; */
}
.navbar a {
    text-decoration: none !important;
}
.navbar .Logo {
    height: 60px;
}
@media screen and (max-width: 1000px) {
    .navbar a {
        font-size: 0.9rem !important;
    }
}
@media screen and (max-width: 576px) {
  
}
