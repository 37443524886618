.Futuristicdapps {
  background-image: url("../../images/futuristicdapps.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.Futuristicdapps .Header {
  height: 30vh;
  padding-bottom: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Futuristicdapps .Header h1 {
  color: #c0c0c0;
  text-shadow: 1px 2px 4px black;
  font-size: 4rem;
  text-align: center;
  width: 100%;
}
.Futuristicdapps .Content {
  width: 90%;
  margin: auto;
  font-size: 1.1rem;
}
@media screen and (max-width: 576px) {
  .Futuristicdapps .Header {
    height: 60vh;
  }
  .Futuristicdapps .Header h1 {
    font-size: 2rem;
  }
  .Futuristicdapps .Header p {
    font-size: 1.3rem;
    width: 90%;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .Futuristicdapps .Header {
    height: 70vh;
  }
  .Futuristicdapps .Header h1 {
    font-size: 3rem;
  }
  .Futuristicdapps .Header p {
    font-size: 1.4rem;
    width: 80%;
  }
}
.FuturisticButton {
  background-color: rgb(84, 214, 240);
  width: fit-content;
  padding: 3px;
  align-items: center;
  align-self: center;
  border-radius: 5px;
}
.FuturisticButton:hover {
  background-color: rgb(239, 231, 142);
  color: rgb(240, 22, 185);
  border: #c0c0c0;
}
