.LegalCompliance {
    background-image: url("../../images/pl.png");
    background-repeat: no-repeat;
    background-size: cover;
}
.LegalCompliance a {
    color: #000000;
}
.LegalCompliance a:hover {
    color: #0000ff !important;
}
.LegalCompliance .Header {
    height: 30vh;
    padding-bottom: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.LegalCompliance .Header h1 {
    color: #c0c0c0;
    text-shadow: 1px 2px 4px black;
    font-size: 4rem;
    text-align: center;
    width: 100%;
}
.LegalCompliance .Content {
    width: 90%;
    margin: auto;
    font-size: 1.1rem;
}
@media screen and (max-width: 576px) {
    .LegalCompliance .Header {
        height: 60vh;
    }
    .LegalCompliance .Header h1 {
        font-size: 2rem;
    }
    .LegalCompliance .Header p {
        font-size: 1.3rem;
        width: 90%;
    }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
    .LegalCompliance .Header {
        height: 70vh;
    }
    .LegalCompliance .Header h1 {
        font-size: 3rem;
    }
    .LegalCompliance .Header p {
        font-size: 1.4rem;
        width: 80%;
    }
}
