.dataPreparation .Header {
    height: 40vh;
    color: #ffffff;
    background-attachment: local;
    background-image: url("../components/images/data_preparation.jpg");
}

#classify {
    background-image: url("../components/images/datap1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    font-weight: 500;
    text-shadow: 2px 0px 10px #ffffff;
    text-align: justify;
    color: #ffffff;
    border-radius: 12px;
    box-shadow: 2px 0 10px #000000;
}

#annotate {
    background-image: url("../components/images/annotate.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    font-weight: 500;
    color: #ffffff;
    text-shadow: 2px 0px 10px #000000;
    text-align: justify;
    border-radius: 12px;
    box-shadow: 2px 0 10px #000000;
}

#transcribe {
    background-image: url("../components/images/transcribe.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    font-weight: 500;
    color: #ffffff;
    text-shadow: 2px 0px 10px #000000;
    text-align: justify;
    background-position: center;
    border-radius: 12px;
    box-shadow: 2px 0 10px #000000;
}

#translate {
    background-image: url("../components/images/datap3.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    font-weight: 500;
    color: #ffffff;
    text-shadow: 2px 0px 10px #000000;
    text-align: justify;
    border-radius: 12px;
    box-shadow: 2px 0 10px #000000;
}
