.DApps {
  background-image: url("../../images/Dappss.webp");
  background-repeat: no-repeat;
  background-size: cover;
}
.DApps .Header {
  height: 30vh;
  padding-bottom: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.DApps .Header h1 {
  color: #c0c0c0;
  text-shadow: 1px 2px 4px black;
  font-size: 4rem;
  text-align: center;
  width: 100%;
}
.DApps .Content {
  width: 90%;
  margin: auto;
  font-size: 1.1rem;
}
@media screen and (max-width: 576px) {
  .DApps .Header {
    height: 60vh;
  }
  .DApps .Header h1 {
    font-size: 2rem;
  }
  .DApps .Header p {
    font-size: 1.3rem;
    width: 90%;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .DApps .Header {
    height: 70vh;
  }
  .DApps .Header h1 {
    font-size: 3rem;
  }
  .DApps .Header p {
    font-size: 1.4rem;
    width: 80%;
  }
}

.Dappsbutton {
  background-color: rgb(84, 214, 240);
  padding: 5px;
  width: 150px;
  height: 40px;
  font-size: larger;
  font-family: "Times New Roman", Times, serif;

  margin-bottom: 20px;
  margin-left: 40px;
  margin-top: 20px;

  border-radius: 25px;
}
.Dappsbutton:hover {
  background-color: rgb(239, 231, 142);
  color: rgb(240, 22, 185);
  border: #c0c0c0;
}
