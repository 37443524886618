.backLayer {
  position: relative;
  width: 100%;
  height: 80vh;
  padding-bottom: 56.25%; /* 16:9 aspect ratio, adjust as needed */
  overflow: hidden;
  z-index: -1;
}
.navbar {
  z-index: 10; /* Ensure it's above the video background */
}

.backLayer video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Cover the entire container */
}

.frontLayer {
  z-index: 1;
  position: absolute;
  top: 30%;
  text-align: center;
  color: white;
}
.frontLayer p {
  font-size: 1.2rem;
  width: 60%;
  margin: 0 auto 2rem auto;
}

@media screen and (max-width: 576px) {
  .frontLayer p {
    width: 95%;
    font-size: 1rem;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .frontLayer p {
    font-size: 1.1rem;
    width: 80%;
  }
}
