.Classify {
    background-color: #000000ee;
    box-sizing: border-box !important;
}
.Classify .Layout {
    width: 80%;
    background-color: #222222;
}
.Classify .Layout textarea {
    background-color: transparent;
    padding: 15px;
    resize: none;
    width: 100%;
    border: none;
    color: white;
}

/* width */
.Classify .Layout textarea::-webkit-scrollbar,
.Classify .ResultLayout .CategoriesSection .CategoryList::-webkit-scrollbar,
.Classify .ResultLayout .KeywordsSection .KeywordList::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.Classify .Layout textarea::-webkit-scrollbar-track,
.Classify .ResultLayout .CategoriesSection .CategoryList::-webkit-scrollbar-track,
.Classify .ResultLayout .KeywordsSection .KeywordList::-webkit-scrollbar-track {
    background: gray !important;
}

/* Handle */
.Classify .Layout textarea::-webkit-scrollbar-thumb,
.Classify .ResultLayout .CategoriesSection .CategoryList::-webkit-scrollbar-thumb,
.Classify .ResultLayout .KeywordsSection .KeywordList::-webkit-scrollbar-thumb {
    background: #ffffff;
    border-radius: 10px;
}

/* Handle on hover */
.Classify .Layout textarea::-webkit-scrollbar-thumb:hover,
.Classify .ResultLayout .CategoriesSection .CategoryList::-webkit-scrollbar-thumb:hover,
.Classify .ResultLayout .KeywordsSection .KeywordList::-webkit-scrollbar-thumb:hover {
    background: #ffffff90;
}
.Classify .ResultLayout {
    width: 100%;
    background-color: #222222;
    color: white;
}
.Classify .ResultLayout .CategoriesSection,
.Classify .ResultLayout .KeywordsSection {
    height: 20vh;
    width: 90%;
    background-color: #111111;
}
.Classify .ResultLayout .CategoriesSection .CategoryList,
.Classify .ResultLayout .KeywordsSection .KeywordList {
    height: 75%;
    overflow-y: scroll;
}
.Classify .ResultLayout .Category,
.Classify .ResultLayout .Keyword {
    height: max-content;
}
.Classify .shakeError {
    animation: shake 0.5s;
}
@keyframes shake {
    0% {
        transform: translateX(0);
    }
    10% {
        transform: translateX(-10px);
    }
    20% {
        transform: translateX(10px);
    }
    30% {
        transform: translateX(-10px);
    }
    40% {
        transform: translateX(10px);
    }
    50% {
        transform: translateX(-10px);
    }
    60% {
        transform: translateX(10px);
    }
    70% {
        transform: translateX(-10px);
    }
    80% {
        transform: translateX(10px);
    }
    90% {
        transform: translateX(-10px);
    }
    100% {
        transform: translateX(0);
    }
}
