.humanSP .header section {
    height: 25rem;
    background-attachment: local;
    background-image: url("../components/images/hsp.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    color: #ffffff;
}
.humanSP a {
    color: #000000b9;
}
.humanSP a:hover {
    color: #000000 !important;
}
